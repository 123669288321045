import { appConstants } from 'src/app/app.constants';
import { ThemeService } from './../shared/services/theming.service';
import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/pages/common/api.service';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { ConfirmEmaiModalComponent } from 'src/app/authentication/confirm-email-modal/confirm-email-modal.component';
import { AESEncryptDecryptService } from 'src/app/authentication/encryption/AESEncryptDecryptService.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DataTransferService } from 'src/app/shared/services/common/datatransfer.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { threadId } from 'worker_threads';
import { S3ImagesConfig } from '../pages/treasures-homepage/homepage.interface';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  providers: [GlobalConstants],
})
export class AuthenticationComponent implements OnInit {
  private subject: Subject<string> = new Subject();
  @Input() showAuthentication: boolean = false;
  @Output() reloadHeader = new EventEmitter();
  IDLE_TIMEOUT = 900;
  _idleSecondsTimer = null;
  _idleSecondsCounter = 0;
  showLoggedOut: boolean = false;
  stepToShow: string;

  modalRef: BsModalRef;
  allCountries: any = [];
  selectedCountry: any;

  showStepZero: boolean = false;
  notApplicableCard = false;

  ifExistingUser: boolean = true;
  showIfNotAuthenticated: boolean = false;
  invalidUser: boolean = false;
  userSearching: boolean = false;
  errorRequestingOtp: boolean = false;
  invalidOtp: boolean = false;
  validOtp: boolean = false;
  invalidCard: boolean = false;
  userId: any;
  urlcode: any;
  uid: any;
  apiclient: any;
  otpInp: any;
  authenticated_user: boolean = false;
  mobileInputBtnActive: any = false;
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  createWithUserForm: any = this.fb.group({
    mobile: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern(this.emailPattern),
      ],
    ],
  });
  get f() {
    return this.createWithUserForm.controls;
  }
  mobNum: any;
  email: any;
  validateUserOtpForm: any = this.fb.group({
    userId: [''],
    otp: [''],
  });
  addCardForm: any = this.fb.group({
    binFirst4: [''],
    binLast4: [''],
  });

  isload1: boolean = false;
  isload2: boolean = false;
  loginProgress: boolean = false;
  timer: any = 'initial';
  viewTimer: any = 0;
  timerToShow: any;
  userStatus: any = 'login';
  userCards: any = [];
  binError: boolean = false;
  binInvalid: boolean = false;
  cardVerified: boolean = false;
  submittedForm1: boolean;
  errorRequestingOtpMsg: string;
  searchLoader: boolean = false;
  otpVerifying: boolean = false;
  showNoCards: boolean = false;
  showNoCardsMessage: any = '';
  fetchingCard: boolean = false;
  cardNum: any;
  featuredCountryCodes = [
    'IND',
    'SGP',
    'ARE',
    'AUS',
    'USA',
    'GBR',
    'CAN',
    'SAU',
    'KWT',
    'QAT',
    'BHR',
    'OMN',
    'HKG',
    'NZL',
    'BGD',
  ];
  popularCountries = [];
  otherCountries = [];
  countrySearchTerm = '';

  currentStep = 0;
  isMobile = false;
  isRelogin = false;
  public isCurrentDomainTreasures: boolean =
  window.location.hostname.includes(appConstants.dbsTreasuresUrlKeyword);
  public treasuresImages: S3ImagesConfig = appConstants.treasures.images;

  clearAll() {
    this.currentStep = 0;
    this.cardNum = null;
    this.fetchingCard = false;
    this.showNoCards = false;
    this.otpVerifying = false;
    this.searchLoader = false;
    this.submittedForm1 = false;
    this.binError = false;
    this.isload1 = false;
    this.isload2 = false;

    this.cardVerified = false;
    this.binInvalid = false;
    this.ifExistingUser = true;
    this.showNoCardsMessage = '';
    this.errorRequestingOtpMsg = '';
    this.createWithUserForm.patchValue({ mobile: '' });
    this.createWithUserForm.patchValue({ email: '' });
    this.mobNum = undefined;
    this.otpInp = undefined;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _apiService: ApiService,
    public GlobalConstants: GlobalConstants,
    private fb: FormBuilder,
    private _auth: AuthenticationService,
    private _modalService: BsModalService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private toastr: ToastrService,
    private dataTransferService: DataTransferService,
    public matDialogRef: MatDialogRef<AuthenticationComponent>,
    public matDialog: MatDialog,
    protected themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._auth.reloadInit.subscribe((res) => {
      if (res) {
        this.reloadInit();
      }
    });
    this._auth.reloadAuth.subscribe((res) => {
      if (res) {
        this.ngOnInit();
      }
    });
    if (this.data && !this.showIfNotAuthenticated) {
      this.restartLogin();
      this.showIfNotAuthenticated = true;
    }
  }

  ngOnInit() {
    this.matDialogRef.afterClosed().subscribe(() => {
      let card_verified = localStorage.getItem('card_verified');
      if (
        !card_verified ||
        (card_verified == 'false' &&
          this.GlobalConstants.data.loginAt == 'card')
      ) {
        this.closeAuthentication();
        this._auth.isUserLoggedIn.emit(false);
      }
    });
    if (localStorage.getItem('currentUser')) {
      let current_user = JSON.parse(localStorage.getItem('currentUser'));
      this.authenticated_user = true;
    } else {
      this.authenticated_user = false;
      this.clearAuthStorage();
    }
    let dbsparams = JSON.parse(sessionStorage.getItem('dbsparams'));
    if (dbsparams) {
      this.processDataQuery(dbsparams);
    } else {
      this.currentStep = 0;
      if (window.innerWidth < 768) {
        this.isMobile = true;
      }
      this.stepToShow = this.GlobalConstants.data.login_steps[0];
      let card_verified = localStorage.getItem('card_verified');
      if (card_verified == 'false') {
        this._auth.logout();
        this.cardVerified = false;
      } else {
        this.cardVerified = true;
      }

      if (localStorage.getItem('currentUser')) {
        let current_user = JSON.parse(localStorage.getItem('currentUser'));
      }
      if (localStorage.getItem('sessionTimeout')) {
        this.isRelogin = true;
        localStorage.removeItem('sessionTimeout');
      }
    }

    this.subject.pipe(debounceTime(500)).subscribe((iinp: string) => {
      this.userSearch();
    });
  }

  processDataQuery(params) {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('thebin');
    sessionStorage.removeItem('dbsparams');
    if (params.cf) {
      let channel_ref = params.cf.toLowerCase();
      localStorage.setItem('channel_ref', channel_ref);
      if (channel_ref == 'mb') {
        localStorage.setItem('appUser', 'true');
      }
    }
    if (params.tk && params.tk != 'no-token' && !this.isCurrentDomainTreasures) {
      this.urlcode = params.tk;
      localStorage.setItem('card_verified', 'true');
      localStorage.setItem('urlcode', this.urlcode);
      var user = {
        token: this.urlcode,
        skipExceptCard: true,
      };
      localStorage.setItem('currentUser', JSON.stringify(user));
      this._apiService.getUserDetails().subscribe(
        (res: any) => {
          this.fetchingCard = true;
          user['id'] = res.id;
          localStorage.setItem('userDetails', JSON.stringify(res));
          localStorage.setItem('currentUser', JSON.stringify(user));
          this._apiService
            .getCardsWithPoints({ fetch_points: true })
            .subscribe((response) => {
              this.fetchingCard = false;
              if (response && response.data && response.data.length > 0) {
                let card = response.data[0];
                localStorage.setItem('userCard', JSON.stringify(card));
                localStorage.setItem(
                  'userCards',
                  JSON.stringify(response.data)
                );
                location.reload();
                // this.reloadInit();
                // this.router.navigate([], { queryParams: { code: null }, queryParamsHandling: 'merge' })
                // window.location.href = "/";
                setTimeout(() => {
                  this.loginProgress = false;
                }, 1000);
              } else {
                window.location.href = '/';
              }
            });
        },
        (err) => {
          this.loginProgress = false;
          this.clearAuthStorage();
        }
      );
    }
  }
  debouncedUserSearch(inp: any) {
    this.subject.next(inp);
  }
  reloadInit() {
    this.ngOnInit();
    this._auth.reloadAuth.emit(true);
  }
  removeLetter(str) {
    this.createWithUserForm.controls['mobile'].setValue(str.replace(/\D/g, ''));
  }

  userSearch() {
    this.isload1 = false;
    this.errorRequestingOtpMsg = '';
    this.userId = '';
    this.ifExistingUser = true;
    this.mobileInputBtnActive = false;
    if (
      this.createWithUserForm.value.mobile.length >
        this.selectedCountry?.min_mobile_digit - 1 &&
      this.createWithUserForm.value.mobile.length <
        this.selectedCountry.max_mobile_digit + 1
    ) {
      this.searchLoader = true;
      this.userSearching = true;
      this.email = null;
      this._auth
        .validateExistingUser(
          this.createWithUserForm.value.mobile,
          this.selectedCountry.calling_codes[0]
        )
        .subscribe((response) => {
          this.mobileInputBtnActive = true;
          this.userSearching = false;
          this.searchLoader = false;
          if (
            response &&
            response.mobile == this.createWithUserForm.value.mobile
          ) {
            this.email = response.email;
            this.ifExistingUser = true;
            this.userId = response.id;

            if (this.GlobalConstants.data.login_theme == 2) {
              this.cardVerified = true;
              localStorage.setItem('card_verified', 'true');
            }
          } else {
            this.userSearching = false;
            this.searchLoader = false;
            this.ifExistingUser = false;
            this.userId = '';
          }
        });
    } else {
      this.mobileInputBtnActive = false;
    }
  }

  closeAuthentication() {
    this.clearAuthStorage();
    this.dataTransferService.nextBannerInterval(5000);
    this.showIfNotAuthenticated = false;
  }
  clearAuthStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('redirectData');
    localStorage.removeItem('headerLogin');
  }
  showMergedOtp: boolean = false;
  authenticateUser(event: any, goTonextStep = true) {
    this.isload1 = true;
    this.errorRequestingOtpMsg = '';
    this.submittedForm1 = true;
    // event.preventDefault();
    if (this.userSearching) {
      this.isload1 = false;
      return false;
    }
    if (this.ifExistingUser && this.userId != '' && this.userId != undefined) {
      this.userStatus = 'login';
      this.requestPoshvineOtp(goTonextStep);
    } else {
      this.isload1 = false;
      return false;
    }
  }

  requestPoshvineOtp(goTonextStep = true) {
    this._auth
      .requestUserOtp(this.createWithUserForm.value.mobile, this.userId)
      .subscribe(
        (response) => {
          if (response) {
            this.submittedForm1 = false;
            this.countDown(180, () => {
              this.timer = 'complete';
            });
            setTimeout(() => {
              this.showStepZero = false;
              this.isload1 = false;
              if (goTonextStep) {
                this.currentStep = this.currentStep + 1;
                this.stepToShow =
                  this.GlobalConstants.data.login_steps[this.currentStep];
              }
            }, 1000);
            setTimeout(() => {
              //<<<---    using ()=> syntax
              document.getElementById('otp-field').focus();
            }, 1800);
          } else {
            this.isload1 = false;
            this.isload2 = false;
            this.errorRequestingOtpMsg =
              'OTP Request limit exceeded. Please try after 30 mins.';
            this.errorRequestingOtp = true;
            this.submittedForm1 = false;
          }
        },
        (error) => {
          this.isload1 = false;
          this.isload2 = false;
          this.submittedForm1 = false;
          if (error.status == 422) {
            this.errorRequestingOtpMsg =
              'OTP Request limit exceeded. Please try after 30 mins.';
            this.errorRequestingOtp = true;
          } else {
            this.toastr.error('Something went wrong. Please try again!');
          }
        }
      );
  }

  validateUserOtp() {
    if (this.otpInp && this.otpInp.length == 6) {
      this.otpVerifying = true;
      this._auth
        .validateUserOtp(this.userId, this.otpInp, this.userStatus, this.mobNum)
        .subscribe(
          (response) => {
            if (response && response.token) {
              localStorage.setItem('card_verified', 'false');
              this.currentStep = this.currentStep + 1;
              this.stepToShow =
                this.GlobalConstants.data.login_steps[this.currentStep];
            } else {
              // if(response && response.wrong_attempts_left && response.wrong_attempts_left == 0){
              //   this.errorRequestingOtpMsg = "Maximum invalid OTP attempts reached";
              // }
              this.invalidOtp = true;
              this.validOtp = false;
              this.otpVerifying = false;
            }
          },
          (err) => {
            console.log(err);
            this.otpVerifying = false;
            this.errorRequestingOtp = true;
            this.errorRequestingOtpMsg = 'Please check the entered OTP';
          }
        );
    }
  }

  checkIfBinValid(bin) {
    this._apiService.validateCard(bin, 'first4_last4').subscribe((res) => {
      this.isload2 = false;
      if (
        res &&
        res.data &&
        res.data.attributes.bin == bin &&
        res.data.attributes.status == 'bin_verified'
      ) {
        let current_user = JSON.parse(localStorage.getItem('currentUser') || '{}');
        current_user.token = res.data.attributes.session_token;
        localStorage.setItem('currentUser', JSON.stringify(current_user));
        this.cardVerified = true;
        localStorage.setItem('card_verified', 'true');
        let card = JSON.stringify({ card_id: res.data.attributes.id });
        localStorage.setItem('userCard', JSON.stringify(res.data));
        let usercurrentcard = JSON.stringify(res);
        // localStorage.setItem('userCard', usercurrentcard);

        this.showIfNotAuthenticated = false;
        this.binError = false;
        this.binInvalid = false;
        this.otpVerifying = false;
        this.validOtp = true;
        // this.reloadInit();
        const redirectData = JSON.parse(localStorage.getItem('redirectData'));
        if (
          res?.data?.attributes.display_name == appConstants.cardNames.vantage
        ) {
          this.themeService.setThemes('dark');
          this.themeService.setActiveCard('vantage');
        } else if (
          res?.data?.attributes.display_name.includes(
            appConstants.cardNames.mass
          )
        ) {
          this.themeService.setThemes('mass');
          this.themeService.setActiveCard('mass');
        } else {
          this.themeService.setThemes('light');
          this.themeService.setActiveCard('bfl');
        }
        this.toastr.success('Logged In Successfully', '', {
          timeOut: 6000,
        });
        this.addLocation();
        if (this.data.callbackOnClose) {
          this._auth.updatePoints.emit(true);
          this.dataTransferService.nextReloadEvent(true);
          this.matDialogRef.close(true);
        } else {
          this.matDialogRef.close();
          if (this.data.redirectUrl) {
            this.router.navigate([this.data.redirectUrl]);
          } else {
            location.reload();
          }
        }
        // this.dataTransferService.nextReloadEvent(true)
        localStorage.removeItem('headerLogin');
      } else {
        this.binError = true;
        this.otpVerifying = false;
        this.toastr.error('Something went wrong. Please try again!');
        localStorage.setItem('card_verified', 'false');
      }
    });
  }

  addLocation() {
    let userLocation = JSON.parse(localStorage.getItem('userLocation'));
    if (userLocation && userLocation.country && userLocation.country.id) {
      let params = {
        country_id: userLocation.country.id,
        // locality_id:
      };
      if (userLocation.state && userLocation.state.id) {
        params['city_id'] = userLocation.state.id;
      }
      this._apiService.addLocation(params).subscribe((res) => {});
    }
  }
  addCardFormSubmit() {
    if (this.binError) {
      this._auth.logout();
      window.location.href = '/';
    }
    this.isload2 = true;
    let bin =
      this.addCardForm.value.binFirst4.toString() +
      '_' +
      this.addCardForm.value.binLast4.toString();
    localStorage.setItem('thebin', bin);
    this.checkIfBinValid(bin);
  }

  getAllCountries(countryCode?) {
    if (!countryCode) {
      var countryCode;
    }
    let allCountries = JSON.parse(localStorage.getItem('allCountries'));
    if (!allCountries || allCountries.length == 0) {
      this._apiService.getAllCountries().subscribe((res: any) => {
        this.processCountries(res.data, countryCode);
        localStorage.setItem('allCountries', JSON.stringify(this.allCountries));
      });
    } else {
      this.processCountries(allCountries, countryCode);
    }
  }
  processCountries(allCountries, countryCode?) {
    this.popularCountries = [];
    this.otherCountries = [];
    this.allCountries = allCountries;
    this.allCountries = this.allCountries.map((ele: any) => {
      if (ele?.attributes?.flag_url.includes('restcountries')) {
        let filename =
          ele?.attributes?.flag_url.split('/')[
            ele?.attributes?.flag_url.split('/').length - 1
          ];
        ele.attributes.flag_url =
          'https://images.poshvine.com/flags/' +
          filename.split('.')[0] +
          '.jpg';
      }
      return ele;
    });
    this.allCountries.sort(function (a, b) {
      var textA = a.attributes.name.toUpperCase();
      var textB = b.attributes.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    if (this.allCountries && this.allCountries.length > 0) {
      this.allCountries.forEach((obj) => {
        let mobile_length = obj.attributes.mobile_length.split('...');
        obj.attributes.min_mobile_digit = parseInt(mobile_length[0]);
        obj.attributes.max_mobile_digit = parseInt(mobile_length[1]);
        if (this.featuredCountryCodes.includes(obj.attributes.iso_code)) {
          this.popularCountries.push(obj);
        } else {
          this.otherCountries.push(obj);
        }
      });
      var country;
      if (countryCode) {
        country = this.allCountries.filter((x) => {
          return x.attributes.code == countryCode;
        });
      } else {
        country = this.allCountries.filter((x) => {
          return x.attributes.name == 'India';
        });
      }
      if (country.length > 0) {
        this.selectedCountry = country[0].attributes;
        localStorage.setItem('userCountry', JSON.stringify(country[0]));
      } else {
        this.selectedCountry = null;
      }

      // sort popular countries array w.r.t featured array
      this.popularCountries.sort((a, b) => {
        return (
          this.featuredCountryCodes.indexOf(a.attributes.iso_code) -
          this.featuredCountryCodes.indexOf(b.attributes.iso_code)
        );
      });

      // alphabetically sort other countries
      this.otherCountries.sort(function (a, b) {
        var textA = a.attributes.name.toUpperCase();
        var textB = b.attributes.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.allCountries = this.popularCountries.concat(this.otherCountries);
      this.filteredCountries = this.allCountries;
    } else {
      this.selectedCountry = null;
    }
  }
  filteredCountries = [];
  filterItem(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.filteredCountries = Object.assign([], this.allCountries).filter(
        (item) =>
          item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1
      );
    }
  }
  setCountry(country) {
    this.selectedCountry = country.attributes;
    localStorage.setItem('userCountry', JSON.stringify(country));
  }
  onKeyph(e) {
    this.binError = false;
    this.binInvalid = false;
    e.target.value = e.target.value.replace(/[^\d]/g, '').trim();
  }
  keytab(event, el) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;
    if (event.currentTarget.value.length == 4) {
      // document.getElementById('last_four_card_digits').focus();
      el.focus();
    }
  }
  btnActive: boolean = false;

  resetLoginAgain() {
    this.clearAll();
    this.submittedForm1 = false;
    this.fetchingCard = false;
    this.searchLoader = false;
    this.isload1 = false;
    this.isload2 = false;
    this.stepToShow = this.GlobalConstants.data.login_steps[0];
    this.currentStep = 0;
    this.mobNum = undefined;
    this.mobileInputBtnActive = false;
    this.errorRequestingOtp = false;
    this.errorRequestingOtpMsg = '';

    if (this.createWithUserForm.controls) {
      if (this.createWithUserForm.controls['mobile']) {
        this.createWithUserForm.controls['mobile'].setValue('');
      }
      if (this.createWithUserForm.controls['otp']) {
        this.createWithUserForm.controls['otp'].setValue('');
      }
    }
    this.otpInp = '';
    this.showIfNotAuthenticated = true;
    this.userSearching = false;
    this.validOtp = false;
    clearInterval(this.int), function () {};
  }
  cardValidation(bin) {
    this._apiService.cardValidation(bin).subscribe((res) => {
      this.isload2 = false;
      if (res && res.success) {
        this.cardVerified = true;
        if (this.allCountries) {
          this.processCountries(this.allCountries, res.detail.country_code);
        } else {
          this.getAllCountries(res.detail.country_code);
        }
        localStorage.setItem('card_verified', 'true');
        this.currentStep = this.currentStep + 1;
        if (this.GlobalConstants.data.login_steps[this.currentStep]) {
          this.stepToShow =
            this.GlobalConstants.data.login_steps[this.currentStep];
        } else {
          this.stepToShow = null;
          this.reloadInit();
        }
        this.binError = false;
        this.binInvalid = false;
      } else {
        this.binError = true;
        if (res && res.detail && res.detail.name) {
          this.notApplicableCard = true;
        } else {
          this.notApplicableCard = true;
        }
        localStorage.setItem('card_verified', 'false');
      }
    });
  }
  checkbtn() {
    let bin =
      this.addCardForm.value.binFirst4.length +
      this.addCardForm.value.binLast4.length;
    if (bin == 8) {
      this.btnActive = true;
      this.addCardFormSubmit();
    } else {
      this.btnActive = false;
    }
  }

  backkeytab(event, firstFocusable) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;

    if (event.currentTarget.value.length == 0 && event.key == 'Backspace') {
      let elem = document.getElementById('first_four_card_digits');
      // elem.value = elem.value.slice(0, 3);
      elem.focus();
    }
  }

  int: any;
  countDown(i, callback) {
    callback = callback || function () {};
    this.int = setInterval(() => {
      this.timer = 'started';
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow = minutes + ':' + (i - minutes * 60);

      i-- || (clearInterval(this.int), callback());
    }, 1000);
  }

  resetCountDown(i, callback) {
    callback = callback || function () {};
    var int = setInterval(() => {
      this.timer = 'started';
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow = minutes + ':' + (i - minutes * 60);

      i-- || (clearInterval(int), callback());
    }, 1000);
  }

  restartLogin() {
    this.clearAll();
    if (!this.allCountries || this.allCountries.length == 0) {
      this.getAllCountries();
    }
    this.currentStep = 0;
    this.stepToShow = this.GlobalConstants.data.login_steps[0];
    this.showIfNotAuthenticated = true;

    this.userSearching = false;
    this.errorRequestingOtp = false;
    this.validOtp = false;
    this.createWithUserForm.patchValue({ mobile: '' });
    this.createWithUserForm.patchValue({ email: '' });
    this.mobNum = undefined;
    this.otpInp = undefined;
    this.errorRequestingOtpMsg = '';
    this.addCardForm.patchValue({ binFirst4: '' });
    this.addCardForm.patchValue({ binLast4: '' });
  }

  clearOtp() {
    document.getElementById('otp-field').focus();
    this.otpInp = '';
  }
  clearCardInput() {
    this.addCardForm.patchValue({ binFirst4: '' });
    this.addCardForm.patchValue({ binLast4: '' });
  }

  theme2loginSetings = {
    showCardInput: false,
    showOtp: false,
  };
  mobileLengthCheck(lengthToCheck) {
    this.theme2loginSetings.showCardInput =
      this.mobNum.length == lengthToCheck ? true : false;
  }
}
